<template>
  <div
    v-if="!isDismissed"
    :class="['k2-callout', `k2-callout-${layout}`, `k2-callout-${theme}`]"
  >
    <div class="k2-callout-header">
      <komodo-icon
        v-if="icon"
        :class="['k2-callout-icon', { 'k2-callout-icon-is-horizontal': isHorizontal }]"
        :size="isVertical ? IconSize.Small : IconSize.Dense"
        :icon="icon"
        :color="iconColor"
      />
      <div
        v-else-if="$slots.graphic"
        :class="`k2-callout-graphic-${layout}`"
      >
        <!-- @slot The graphic to display -->
        <slot name="graphic" />
      </div>

      <div class="k2-header-content">
        <div class="k2-callout-title">
          {{ title }}
        </div>

        <div
          v-if="isHorizontal"
          class="k2-callout-body"
        >
          <!-- @slot The contents to display in the callout body -->
          <slot />
        </div>
      </div>

      <div
        v-if="$slots.actions && isHorizontal"
        class="k2-callout-actions"
      >
        <!-- @slot The buttons to display in the callout -->
        <slot name="actions" />
      </div>

      <komodo-icon
        v-if="dismissable"
        v-tegulytics-track="getTrackingEvent('dismiss')"
        class="k2-callout-dismiss"
        :size="IconSize.Small"
        icon="close"
        color="text_primary_text"
        @click="onDismiss"
      />
    </div>

    <div
      v-if="isVertical"
      class="k2-callout-body"
    >
      <!-- @slot The contents to display in the callout body -->
      <slot />
    </div>

    <div
      v-if="$slots.actions && isVertical"
      class="k2-callout-actions"
    >
      <!-- @slot The buttons to display in the callout -->
      <slot name="actions" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'

import KomodoIcon from 'komodo2/components/KomodoIcon.vue'

import { CalloutLayout, CalloutTheme, IconSize } from 'komodo2/types.ts'
import { useDismissable } from 'komodo2/composables/useDismissable.ts'
import { useTrackable } from 'komodo2/composables/trackable.ts'

export default {
  name: 'KomodoCallout'
}
</script>

<script setup lang="ts">
interface Props {
  /**
   * The icon to display (in Vertical mode)
   */
  icon?: string
  /**
   * The title to display
   */
  title: string
  /**
   * Indicates whether or not to render an icon to allow dismissing the callout
   */
  dismissable?: boolean
  /**
   * The layout in which the callout is structured
   */
  layout?: CalloutLayout
  /**
   * The color theme to use in the callout
   */
  theme?: CalloutTheme
  /**
   * The number of seconds that the component can be dismissed (zero means forever)
   */
  dismissalDuration?: number
}

const props = withDefaults(defineProps<Props>(), {
  icon: '',
  dismissable: false,
  layout: CalloutLayout.Vertical,
  theme: CalloutTheme.Primary,
  dismissalDuration: 0
})

const emit = defineEmits<{
  (e: 'dismissed'): void
}>()

const { dismiss, isDismissed } = useDismissable(props)
const { getTrackingEvent } = useTrackable(props)
const isHorizontal = computed(() => {
  return props.layout === CalloutLayout.Horizontal
})

const isVertical = computed(() => {
  return props.layout === CalloutLayout.Vertical
})

const iconColor = computed(() => {
  return props.theme === CalloutTheme.Marketing ? 'marketing.darken2' : 'tegus.secondary'
})

const onDismiss = () => {
  dismiss()

  /**
     * Triggers when the user clicks the icon to dismiss the callout
     *
     * @event dismissed
     */
  emit('dismissed')
}
</script>

<style lang="scss" scoped>
$vertical-graphic-size: 20px;
$horizontal-graphic-size: 20px;
$horizontal-graphic-size-alt: 32px;
$callout-themes: (
    'primary': $k2-secondary-lighten-6,
    'marketing': $k2-secondary-lighten-6,
  );

.k2-callout {
  .k2-callout-header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .k2-callout-icon {
      margin-right: 8px;

      &.k2-callout-icon-is-horizontal {
        margin-right: 12px;
      }
    }

    .k2-header-content {
      flex-grow: 1;

      .k2-callout-title {
        @include k2-font-headline-headline-4;
        @include k2-color-text-surface-primary-text;
      }

      & + * {
        margin-left: 8px;
      }
    }

    .k2-callout-dismiss {
      cursor: pointer;
    }
  }

  .k2-callout-body {
    @include k2-font-text-text;
    @include k2-color-text-tertiary-text;
  }

  .k2-callout-actions {
    display: flex;
    align-items: center;
    margin-top: 12px;
  }

  @each $name, $color in $callout-themes {
    &.k2-callout-#{$name} {
      background-color: $color;
      border-radius: $k2-border-radius-default;
      padding: 12px;

      &.k2-callout-horizontal {
        padding: 8px 12px;
      }

      @if $name == 'marketing' {
        .k2-callout-header {
          .k2-callout-body {
            @include k2-font-text-text-med;
          }

          .k2-callout-graphic-horizontal {
            min-height: $horizontal-graphic-size-alt;
            max-height: $horizontal-graphic-size-alt;
            min-width: $horizontal-graphic-size-alt;
            max-width: $horizontal-graphic-size-alt;
          }

          .k2-callout-actions {
            height: $horizontal-graphic-size-alt;
            flex-direction: row-reverse;
            margin-top: 0;
            margin-left: 20px;
          }
        }

      }
    }
  }

  .k2-callout-graphic-vertical {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: $vertical-graphic-size;
    max-height: $vertical-graphic-size;
    min-width: $vertical-graphic-size;
    max-width: $vertical-graphic-size;
    margin-right: 8px;
  }

  &.k2-callout-horizontal {
    .k2-callout-graphic-horizontal {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: $horizontal-graphic-size;
      max-height: $horizontal-graphic-size;
      min-width: initial;
      max-width: $horizontal-graphic-size;
      margin-right: 12px;
    }

    .k2-callout-header {
      margin-bottom: 0;

      .k2-callout-title {
        margin-bottom: 4px;
      }
    }

    .k2-callout-actions {
      height: $horizontal-graphic-size;
      flex-direction: row-reverse;
      margin-top: 0;
      margin-left: 20px;
    }

    .k2-callout-dismiss {
      margin-left: 20px;
      height: $horizontal-graphic-size;
    }
  }
}
</style>
